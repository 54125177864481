import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { deleteDevice } from "~/components/devices/fetchers";
import { useLocaleOptions } from "~/components/header/fetchers";

import { defineMessages, useIntl } from "~/intl";

import { HttpResponse } from "~/services/http";

import { toast } from "~/shared/components/toast/use-toast";

import { addTenant, deleteTenant, fetchTenants, linkUpdateDevice, updateTenant } from "./fetchers";

export function useLocale(localeName: string | undefined) {
  const { data: localeOptions = [] } = useLocaleOptions();
  return localeOptions.find((locale) => locale.value === localeName)?.label || "";
}

export function useLinkUpdateDeviceMutation(
  isEditDevice: boolean,
  activeDevice: string | null,
  activeTenant: string | null,
) {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationFn: linkUpdateDevice,
    onSuccess: () => {
      toast({
        meaning: "success",
        text: formatMessage(!isEditDevice ? t.linkDeviceSuccessMessage : t.editDeviceSuccessMessage),
      });
      if (isEditDevice) {
        queryClient.invalidateQueries({
          queryKey: ["device", activeTenant, activeDevice],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ["unknownDevices"],
        });

        queryClient.invalidateQueries({
          queryKey: ["devices", activeTenant],
        });
      }
    },
  });
}

export function useUpdateTenantMutation() {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationFn: updateTenant,
    onSuccess: () => {
      toast({
        meaning: "success",
        text: formatMessage(t.updateTenantSuccessMessage),
      });
      queryClient.invalidateQueries({
        queryKey: ["tenant"],
      });
    },
  });
}

export function useAddTenantMutation() {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  return useMutation({
    mutationFn: addTenant,
    onSuccess: () => {
      toast({
        meaning: "success",
        text: formatMessage(t.addTenantSuccessMessage),
      });
      queryClient.invalidateQueries({
        queryKey: ["tenants"],
      });
    },
  });
}

export function useRemoveTenantMutation() {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  return useMutation({
    mutationFn: deleteTenant,
    onSuccess: () => {
      toast({
        meaning: "success",
        text: formatMessage(t.deleteTenantSuccessMessage),
      });
      queryClient.invalidateQueries({
        queryKey: ["tenants"],
      });
    },
    onError: (error) => {
      if (error instanceof HttpResponse && error.status === 422) {
        error.errors.forEach((e) => {
          toast({
            meaning: "danger",
            text: e.detail,
          });
        });
      }
    },
  });
}

export function useDeleteDeviceMutation() {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  return useMutation({
    mutationFn: deleteDevice,
    onSuccess: () => {
      toast({
        meaning: "success",
        text: formatMessage(t.deleteDeviceSuccessMessage),
      });
      queryClient.invalidateQueries({
        queryKey: ["unknownDevices"],
      });
    },
    onError: (error) => {
      if (error instanceof HttpResponse && error.status === 422) {
        error.errors.forEach((e) => {
          toast({
            meaning: "danger",
            text: e.detail,
          });
        });
      }
    },
  });
}

export function useTenantOptions() {
  return useQuery({
    queryKey: ["tenantOptions"],
    queryFn: () => fetchTenants(),
    select: (tenants) =>
      tenants.map((t) => ({
        label: t.name,
        value: t.id,
      })),
  });
}

const t = defineMessages({
  linkDeviceSuccessMessage: {
    id: "settings_device_link_success_message",
    defaultMessage: "Device linked successfully.",
  },
  editDeviceSuccessMessage: {
    id: "settings_device_edit_success_message",
    defaultMessage: "Device updated successfully.",
  },
  updateTenantSuccessMessage: {
    id: "settings_tenant_udpate_success_message",
    defaultMessage: "Client updated successfully.",
  },
  deleteTenantSuccessMessage: {
    id: "settings_tenant_delete_success_message",
    defaultMessage: "Client deleted successfully.",
  },
  deleteDeviceSuccessMessage: {
    id: "settings_device_delete_success_message",
    defaultMessage: "Device deleted successfully.",
  },
  addTenantSuccessMessage: {
    id: "settings_tenant_add_success_message",
    defaultMessage: "Client created successfully.",
  },
});
